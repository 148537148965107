




































import {Component, Mixins, Watch} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {WalletPlatform} from '@/enums/WalletPlatform'
import ChooseWallet from '@/components/walletConnection/ChooseWallet.vue'
import {ConnectWalletStep} from '@/enums/ConnectWalletStep'
import NeonConnect from '@/components/walletConnection/NeonConnect.vue'
import {UnimplementedWallet} from '@/model/wallets/types/WalletErrors'

@Component({
  computed: {
    ConnectWalletStep() {
      return ConnectWalletStep
    },
  },
  components: {NeonConnect, ChooseWallet},
})
export default class ConnectWalletModal extends Mixins(MixinScreenSize) {
  actionToDoAfterConnect: (() => Promise<void> | void) | null = null
  modalState = ConnectWalletStep.CHOOSE_WALLET
  isWaitingConnection = false

  walletInUse: 'n3' | 'x' | null = null

  resetModal() {
    this.isWaitingConnection = false
    this.walletInUse = null
    this.modalState = ConnectWalletStep.CHOOSE_WALLET
  }

  async handleConnectNeonN3() {
    this.modalState = ConnectWalletStep.NEON_CONNECT
    this.walletInUse = 'n3'
    await this.$walletAdapter.connectN3Wallet(WalletPlatform.NEON)
  }

  async handleConnectNeonX() {
    this.modalState = ConnectWalletStep.NEON_CONNECT
    this.walletInUse = 'x'

    await this.$walletAdapter.connectXWallet(WalletPlatform.NEON)
  }

  async handleConnectNeoline() {
    if (await this.$walletAdapter.canUseN3Wallet(WalletPlatform.NEOLINE)) {
      await this.$walletAdapter.connectN3Wallet(WalletPlatform.NEOLINE)
      this.walletInUse = 'n3'
    } else {
      window.open(
        'https://chromewebstore.google.com/detail/neoline/cphhlgmgameodnhkjdmkpanlelnlohao?pli=1'
      )
    }
  }

  async handleWalletConnection(
    namespace: 'n3' | 'x',
    wallet: WalletPlatform
  ): Promise<void> {
    try {
      this.isWaitingConnection = true

      if (namespace === 'x' && wallet === WalletPlatform.NEON) {
        await this.handleConnectNeonX()
      } else if (namespace === 'n3' && wallet === WalletPlatform.NEON) {
        await this.handleConnectNeonN3()
      } else if (namespace === 'n3' && wallet === WalletPlatform.NEOLINE) {
        await this.handleConnectNeoline()
      } else {
        throw UnimplementedWallet
      }

      this.$modal.close('connectWalletModal')
    } catch {
      this.resetModal()
    }
  }

  async openEvent(actionToDoAfterConnect?: () => Promise<void> | void) {
    if (typeof actionToDoAfterConnect !== 'undefined') {
      this.actionToDoAfterConnect = actionToDoAfterConnect
    }
    this.$emit('open')
  }

  async closeEvent() {
    this.resetModal()
    this.$emit('close')

    if (this.actionToDoAfterConnect && this.$walletAdapter.n3Address)
      await this.actionToDoAfterConnect()
  }

  @Watch('$store.state.walletAdapter.n3Address')
  onAlreadyConnected(address: string | null) {
    if (address) this.closeEvent()
  }
}
