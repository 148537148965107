

























































































































import {Vue, Component, Watch, Prop} from 'vue-property-decorator'
import QrcodeVue from 'qrcode.vue'

@Component({
  components: {QrcodeVue},
})
export default class NeonConnect extends Vue {
  @Prop({type: String, default: null}) walletInUse!: 'n3' | 'x' | null

  n3Base64URI: string | null = null
  xBase64URI: string | null = null

  n3Uri: string | null = null
  xUri: string | null = null

  get base64URI() {
    if (!this.walletInUse) {
      return ''
    }

    if (this.walletInUse === 'n3') {
      return this.n3Base64URI ?? ''
    }

    return this.xBase64URI ?? ''
  }

  get uri() {
    if (!this.walletInUse) {
      return ''
    }

    if (this.walletInUse === 'n3') {
      return this.n3Uri ?? ''
    }

    return this.xUri ?? ''
  }

  copy() {
    if (!this.walletInUse) {
      return
    }

    if (this.walletInUse === 'n3') {
      this.$utils.copyToClipboard(this.n3Uri ?? '')
    }

    if (this.walletInUse === 'x') {
      this.$utils.copyToClipboard(this.xUri ?? '')
    }

    this.$toast.info('system.info.copiedToClipboard')
  }

  @Watch('$store.state.walletAdapter.n3Uri')
  onN3UriChange(uri: string | null) {
    if (!uri) {
      this.n3Base64URI = null
      this.n3Uri = ''
      return
    }
    this.n3Uri = uri
    this.n3Base64URI = Buffer.from(uri).toString('base64')
  }

  @Watch('$store.state.walletAdapter.xUri')
  onXUriChange(uri: string | null) {
    if (!uri) {
      this.xBase64URI = null
      this.xUri = ''
      return
    }
    this.xUri = uri
    this.xBase64URI = Buffer.from(uri).toString('base64')
  }
}
